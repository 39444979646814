<template>
  <div class="subscribeNews" :class="dialog ? 'dialog' : ''">
    <h3 class="NotoSansTCBold">
      {{ $t("localization.components.subscribeNews.title") }}
    </h3>
    <div class="form-item">
      <p v-if="!dialog">
        {{ $t("localization.components.subscribeNews.txt1") }}
      </p>
      <div v-else class="txt">
        訂閱我們的時事動態<br />
        並將最新的博銳新聞直接發送到您的收件箱！
      </div>
      <el-input
        class="inputView"
        v-model="name"
        :placeholder="$t('localization.components.subscribeNews.name')"
      ></el-input>
      <el-select
        class="inputView"
        v-model="language"
        :placeholder="$t('localization.components.subscribeNews.language')"
        style="width: 100%"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <p>
        <el-checkbox class="checkbox" v-model="checked"></el-checkbox
        >{{ $t("localization.components.subscribeNews.txt2") }}
      </p>
    </div>
    <div class="form-item text">
      <el-input
        class="inputView"
        v-model="email"
        :placeholder="$t('localization.components.subscribeNews.mailbox')"
      ></el-input>
    </div>
    <el-button
      class="submit"
      type="primary"
      :disabled="!checked"
      @click="submit"
      round
      >{{ $t("localization.components.subscribeNews.submit") }}</el-button
    >
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      name: "",
      options: [
        {
          value: "中文简体",
          label: "中文简体",
        },
        {
          value: "中文繁体",
          label: "中文繁体",
        },
      ],
      language: "",
      email: "",
      checked: false,
    };
  },
  mounted() {},
  methods: {
    submit() {
      let parameter = {
        email: this.email,
        name: this.name,
        language: this.language,
        type: 4,
      };
      if (parameter.name == "") {
        this.$message({
          message: this.$t(
            "localization.components.subscribeNews.verification.name"
          ),
          type: "warning",
        });
        return;
      }
      if (parameter.language == "") {
        this.$message({
          message: this.$t(
            "localization.components.subscribeNews.verification.language"
          ),
          type: "warning",
        });
        return;
      }
      if (parameter.email == "") {
        this.$message({
          message: this.$t(
            "localization.components.subscribeNews.verification.mailbox"
          ),
          type: "warning",
        });
        return;
      }
      // if (!isEmail(this.mailbox).status) {
      //   this.$message({
      //     message: this.$t(
      //       "localization.components.subscribeNews.verification.isEmail"
      //     ),
      //     type: "warning",
      //   });
      //   return;
      // }
      this.$http.postData(parameter).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.language = "";
          this.email = "";
          this.name = "";
          this.checked = false;
          this.$emit("success");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.subscribeNews {
  h3 {
    text-align: center;
    font-size: 32px;
    color: #1a1a1a;
    margin-bottom: 28px;
    @include respond-to(sm) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  .form-item {
    margin-bottom: 40px;
    .inputView {
      margin-bottom: 20px;
    }
    p {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #333;
      margin-bottom: 6px;
      @include respond-to(sm) {
        font-size: 14px;
      }
      .checkbox {
        margin-right: 5px;
      }
    }
  }
  .submit {
    background-color: #0075c1;
    width: 100%;
    &.is-disabled {
      color: #fff;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
  &.dialog {
    padding: 0 40px 20px;
    h3 {
      margin-bottom: 10px;
    }
    .txt {
      font-size: 14px;
      color: #656565;
      line-height: 2;
      text-align: center;
      margin-bottom: 30px;
      @include respond-to(sm) {
        font-size: 12px;
      }
    }
    p {
      color: #c0c4cc;
      font-size: 14px;
      @include respond-to(sm) {
        font-size: 12px;
      }
    }
    .form-item {
      margin-bottom: 20px;
      &.text {
        margin-top: 40px;
      }
    }
    .submit {
      width: 260px;
      margin: 0 auto;
      display: block;
    }
  }
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333;
  border-color: #333;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #333;
}
/deep/ .el-checkbox__inner:hover {
  border-color: #333;
}
</style>