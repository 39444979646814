import Vue from "vue";
import Vuex from "vuex";
import cookie from "vue-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "",
    screenWidth: document.body.clientWidth
  },
  getters: {
    getLang(state) {
      return state.lang ? state.lang : cookie.get("DefaultLanguage");
    },
  },
  mutations: {
    setLang(state, name) {
      state.lang = name;
      cookie.set("DefaultLanguage", name, 1);
    },
    /**
     * 设置屏幕宽度
     * @param {*} state 
     * @param {*} val 
     */
    setScreenWidth(state, val) {
      state.screenWidth = val;
    },
  },
  actions: {
    // 参数列表：{commit, state}
    // state指的是state数据
    // commit调用mutations的方法
    // name就是调用此方法时要传的参数
    setLangName({
      commit,
      state
    }, name) {
      commit("setLang", name);
    },
  },
  modules: {},
});