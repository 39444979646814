import $http from '@/request';
import qs from 'qs'

let api = {

    /**
     * 首页
     */
    home: function () {
        return $http.get('/api/index/home');
    },

    /**
     * 产品筛选数据
     */
    productSelectList: function () {
        return $http.get(`/api/index/productSelectList`);
    },

    /**
     * 产品列表
     */
    product: function (data) {
        return $http.get(`/api/index/productList?${qs.stringify(data)}`);
    },

    /**
     * 产品详情
     */
    productDetails: function (data) {
        return $http.get(`/api/index/productDetail?${qs.stringify(data)}`);
    },



    /**
     * 配件筛选
     */
    partSelectList: function () {
        return $http.get(`/api/index/partSelectList`);
    },

    /**
     * 配件列表
     */
    parts: function (data) {
        return $http.get(`/api/index/productPartsList?${qs.stringify(data)}`);
    },

    /**
     * 配件详情
     */
    productPartsDetail: function (data) {
        return $http.get(`/api/index/productPartsDetail?${qs.stringify(data)}`);
    },
    /**
     * 零件详情
     */
    sparePartsDetail: function (data) {
        return $http.get(`/api/index/sparePartsDetail?${qs.stringify(data)}`);
    },

    /**
     * 配件列表 - 零件
     */
    sparePartsList: function () {
        return $http.get(`/api/index/sparePartsList`);
    },

    /**
     * 新闻列表
     */
    news: function (data) {
        return $http.get(`/api/index/newsList?${qs.stringify(data)}`);
    },

    /**
     * 新闻详细
     */
    newsDetail: function (data) {
        return $http.get(`/api/index/newsDetail?${qs.stringify(data)}`);
    },

    /**
     * 新闻列表筛选
     */
    news_screen: function () {
        return $http.get(`/api/index/newsSelectList`);
    },

    /**
     * 关于博锐
     */
    about: function () {
        return $http.get('/api/index/about');
    },

    /**
     * 校正与维修
     */
    calibrationAndMaintenance: function () {
        return $http.get('/api/index/calibrationMaintenance');
    },

    /**
     * 产品详情&& 下载中心 筛选1
     */
    productSelectListAll: function () {
        return $http.get(`/api/index/productSelect`);
    },
    /**
     * 下载中心 筛选2
     */
    downloadCenter_screen_two: function () {
        return $http.get('/api/index/downloadSelectList');
    },
    /**
     * 下载中心 数据
     */
    downloadCenter: function (data) {
        return $http.get(`/api/index/downloadCenter?${qs.stringify(data)}`);
    },
    /**
     * 线上教学-教学分类
     */
    onlineTeachingCate: function () {
        return $http.get('/api/index/onlineTeachingCate');
    },
    /**
     * 线上教学
     */
    postData: function (data) {
        return $http.post('/api/index/createOnlineTeaching', data);
    },
    /**
     * 知识库列表数据
     */
    knowledgeBaseUpList: function (data) {
        return $http.post('/api/knowledgeBaseUpList', data);
    },
    /**
     * 知识库详情
     */
    knowledgeBaseUpDetails: function (data) {
        return $http.get(`/api/index/knowledgeDetail?${qs.stringify(data)}`);
    },
    /**
     * 知识库一级分类 二级分类
     */
    knowledgeCate: function (data) {
        return $http.get(`/api/index/knowledgeCate?${qs.stringify(data)}`, {
            loading: false
        });
    },
    /**
     * 知识库
     */
    knowledgeBase: function (data) {
        return $http.post('/api/knowledgeBase', data);
    },
    /**
     * 知识库-教学视频
     */
    knowledgeBaseVideo: function (data) {
        return $http.post('/api/knowledgeBaseVideo', data);
    },
    /**
     * 知识库-列表
     */
    knowledgeBaseList: function (data) {
        return $http.get(`/api/index/knowledgeList?${qs.stringify(data)}`);
    },

    /**
     * 搜索列表
     */
    search: function (data) {
        return $http.get(`/api/index/search?${qs.stringify(data)}`);
    },

    /**
     * 运营据点
     */
    operationBase: function () {
        return $http.get(`/api/index/strongholdList`);
    },

    /**
     * 运营据点详情
     */
    operationBaseDetails: function (data) {
        return $http.get(`/api/index/strongholdDetail?${qs.stringify(data)}`);
    },

    /**
     * 使用案例-筛选
     */
    use_cases_screen: function () {
        return $http.get('/api/index/casesSelect');
    },

    /**
     * 使用案例-列表
     */
    casesList: function (data) {
        return $http.get(`/api/index/casesList?${qs.stringify(data)}`);
    },

    /**
     * 使用案例-详情
     */
    use_cases_details: function (data) {
        return $http.get(`/api/index/casesDetail?${qs.stringify(data)}`);
    },

    /**
     * 1隐私政策 2使用条款
     */
    pages: function (data) {
        return $http.get(`/api/index/pages?${qs.stringify(data)}`);
    },

    /**
     * footer 配置
     */
    getConfig: function (data) {
        return $http.get(`/api/index/getConfig?${qs.stringify(data)}`);
    },
    /**
     * 产品咨询技术支持底部图片
     */
    getProductSupportBanner: function () {
        return $http.get(`/api/index/getProductSupportBanner`);
    },
    /**
     * rpa首页产品页入口图  zd 20220811
     */
    getRpaEntranceBanner: function () {
        return $http.get(`/api/index/rpaEntrance`);
    },
    /**
     * rpa页面  zd 20220812
     */
    getRpaUltra: function () {
        return $http.get(`/api/index/rpaUltra`);
    },
    /**
     * 获取产品行业  zd 20240520
     */
    getProductIndustryList: function () {
        return $http.get(`/api/index/productIndustryList`);
    },


}

export default api