import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//字体图标
import "@/assets/iconfont/iconfont.css";
//字体包
// import "@/assets/fonts/font.scss";

import cookie from "vue-cookie";
Vue.prototype.$cookie = cookie;

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import * as utils from "./common/utils";
Vue.prototype.$utils = utils;

import emptyData from './components/empty-data'
Vue.component('emptyData', emptyData);
import loading from './components/loading'
Vue.component('loading', loading);

//动画
import animated from 'animate.css'
Vue.use(animated)

// 请求
import http from "@/request/api";
Vue.prototype.$http = http;
//mock数据模拟
import "@/request/mock";

//国际化
import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import twLocale from "element-ui/lib/locale/lang/zh-TW";
Vue.use(VueI18n);
Vue.use(ElementUI, {
  zhLocale
});

// InitLanguage  这里引入了vue-cookie,是为了将用户选择的语言存储到Cookie里，在以后访问站点不需要重新选择语言
// https://www.cnblogs.com/Mxy-cnblog/p/9242305.html
function InitLanguage() {
  // let Lang = cookie.get("DefaultLanguage") == null ? "zh-TW" : cookie.get("DefaultLanguage");
  // store.dispatch("setLangName", "zh-TW");
  // return Lang
  store.dispatch("setLangName", "zh-TW");
  return "zh-TW"
}

const i18n = new VueI18n({
  locale: InitLanguage(), // 语言标识,默认汉语,先去cookie查找，如果存在并有效，cookie值即为默认语言类型；否则默认为中文简体
  messages: {
    "zh-CN": Object.assign(require("./assets/lang/zh-CN"), zhLocale),
    "zh-TW": Object.assign(require("./assets/lang/zh-TW"), twLocale),
  },
  silentFallbackWarn: true, // 设置为true后，在组件内使用时在浏览器不会报警告
});

locale.i18n((key, value) => i18n.t(key, value)); //为了之后将element-ui组件本地化

//阻止启动生产消息，常用作指令
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");