import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import NProgress from "nprogress";
import "nprogress/nprogress.css";

const routes = [{
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      keepAlive: true,
      name: "home",
      title: "首页"
    },
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/Product.vue"),
    meta: {
      keepAlive: false,
      name: "product",
      title: "产品"
    },
  },
  {
    path: "/product_details",
    name: "product_details",
    component: () => import("../views/ProductDetails.vue"),
    meta: {
      keepAlive: false,
      name: "product",
      title: "产品详情"
    },
  },
  {
    path: "/parts",
    name: "parts",
    component: () => import("../views/Parts.vue"),
    meta: {
      keepAlive: false,
      name: "parts",
      title: "配件"
    },
  },
  {
    path: "/parts_details",
    name: "parts_details",
    component: () => import("../views/PartsDetails.vue"),
    meta: {
      keepAlive: false,
      name: "parts",
      title: "配件详情"
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: {
      keepAlive: false,
      name: "about",
      title: "关于博锐"
    },
  },
  {
    path: "/service_and_support",
    name: "service_and_support",
    component: () => import("../views/ServiceAndSupport.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "服务与支持"
    },
  },
  {
    path: "/calibration_and_maintenance",
    name: "calibration_and_maintenance",
    component: () => import("../views/CalibrationAndMaintenance.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "校正与维修"
    },
  },
  {
    path: "/download_center",
    name: "download_center",
    component: () => import("../views/DownloadCenter.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "下载中心"
    },
  },
  {
    path: "/online_teaching",
    name: "online_teaching",
    component: () => import("../views/OnlineTeaching.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "线上教学"
    },
  },
  {
    path: "/knowledge_base",
    name: "knowledge_base",
    component: () => import("../views/KnowledgeBase.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "知识库"
    },
  },
  {
    path: "/knowledge_base_list",
    name: "knowledge_base_list",
    component: () => import("../views/KnowledgeBaseList.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "知识库列表"
    },
  },
  {
    path: "/knowledge_base_detailed",
    name: "knowledge_base_detailed",
    component: () => import("../views/KnowledgeBaseDetailed.vue"),
    meta: {
      keepAlive: false,
      name: "service_and_support",
      title: "知识库详情"
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/News.vue"),
    meta: {
      keepAlive: false,
      name: "news",
      title: "新闻"
    },
  },
  {
    path: "/news_details",
    name: "news_details",
    component: () => import("../views/NewsDetails.vue"),
    meta: {
      keepAlive: false,
      name: "news_details",
      title: "新闻详情"
    },
  },
  {
    path: "/operation_base",
    name: "operation_base",
    component: () => import("../views/OperationBase.vue"),
    meta: {
      keepAlive: false,
      name: "operation_base",
      title: "营运据点"
    },
  },
  {
    path: "/operation_base_detailed",
    name: "operation_base_detailed",
    component: () => import("../views/OperationBaseDetailed.vue"),
    meta: {
      keepAlive: false,
      name: "operation_base_detailed",
      title: "营运据点详情"
    },
  },
  {
    path: "/use_cases",
    name: "use_cases",
    component: () => import("../views/UseCases.vue"),
    meta: {
      keepAlive: false,
      name: "use_cases",
      title: "使用案例"
    },
  },
  {
    path: "/use_cases_details",
    name: "use_cases_details",
    component: () => import("../views/UseCasesDetails.vue"),
    meta: {
      keepAlive: false,
      name: "use_cases_details",
      title: "使用案例详情"
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/Search.vue"),
    meta: {
      keepAlive: false,
      name: "search",
      title: "搜索"
    },
  },
  {
    path: "/terms_of_use",
    name: "terms_of_use",
    component: () => import("../views/TermsOfUse.vue"),
    meta: {
      keepAlive: false,
      name: "terms_of_use",
      title: "使用条款"
    },
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: {
      keepAlive: false,
      name: "privacy_policy",
      title: "隐私条款"
    },
  },
  {
    path: "/product_consulting",
    name: "product_consulting",
    component: () => import("../views/ProductConsulting.vue"),
    meta: {
      keepAlive: false,
      name: "product_consulting",
      title: "产品咨询"
    },
  },
  {
    path: "/technical_support",
    name: "technical_support",
    component: () => import("../views/TechnicalSupport.vue"),
    meta: {
      keepAlive: false,
      name: "technical_support",
      title: "技术支持"
    },
  },
  {
    path: "/rpa-ultra",
    name: "rpa-ultra",
    component: () => import("../views/RpaUltra.vue"),
    meta: {
      keepAlive: false,
      name: "rpa-ultra",
      title: "RPA Ultra"
    },
  },
];

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL, //默认值: "/" , 应用的基路径。例如，如果整个单页应用服务在 /app/ 下，然后 base 就应该设为 "/app/" https://router.vuejs.org/zh/api/#base
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.path == "/knowledge_base_list" && JSON.stringify(to.query) != "{}") {
        return {
          x: 0,
          y: 600
        }

      } else {
        return {
          x: 0,
          y: 0
        }
      }
    }
  }
});

//路由守卫
router.beforeEach((to, from, next) => {
  // 进度条
  NProgress.start(); // 显示进度条
  NProgress.set(0.4); // 设置百分比
  NProgress.inc(); // 稍微增加

  if (to.matched.some((record) => record.meta.requireLogin)) {
    if (!to.meta.requireLogin) {
      next();
    } else {
      let token = localStorage.getItem("token");
      if (!token) {
        // next({ path: '/login', query: { redirect: to.fullPath } });
        next();
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
router.afterEach(() => {
  NProgress.done(); //完成进度(进度条消失)
});

export default router;