<template>
  <div class="footer">
    <div class="footerView">
      <el-row>
        <el-col :span="18" class="leftView">
          <el-row class="navWrap">
            <el-col :span="6">
              <h3 class="NotoSansTCMedium">{{ $t("localization.footer.nav")[0]["title"] }}</h3>
              <nav>
                <router-link class="link" to="/about">{{
                  $t("localization.footer.nav")[0]["link"][0]
                }}</router-link>
                <router-link class="link" to="/news">{{
                  $t("localization.footer.nav")[0]["link"][1]
                }}</router-link>
                <router-link class="link" to="/use_cases">{{
                  $t("localization.footer.nav")[0]["link"][2]
                }}</router-link>
                <router-link class="link" to="/operation_base">{{
                  $t("localization.footer.nav")[0]["link"][3]
                }}</router-link>
              </nav>
            </el-col>
            <el-col :span="6">
              <h3 class="NotoSansTCMedium">{{ $t("localization.footer.nav")[1]["title"] }}</h3>
              <nav>
                <router-link class="link" to="/product">{{
                  $t("localization.footer.nav")[1]["link"][0]
                }}</router-link>
                <router-link class="link" to="/parts">{{
                  $t("localization.footer.nav")[1]["link"][1]
                }}</router-link>
              </nav>
            </el-col>
            <el-col :span="6">
              <h3 class="NotoSansTCMedium">{{ $t("localization.footer.nav")[2]["title"] }}</h3>
              <nav>
                <router-link class="link" to="/calibration_and_maintenance">{{
                  $t("localization.footer.nav")[2]["link"][0]
                }}</router-link>
                <router-link class="link" to="/download_center">{{
                  $t("localization.footer.nav")[2]["link"][1]
                }}</router-link>
                <router-link class="link" to="/online_teaching">{{
                  $t("localization.footer.nav")[2]["link"][2]
                }}</router-link>
                <router-link class="link" to="/knowledge_base">{{
                  $t("localization.footer.nav")[2]["link"][3]
                }}</router-link>
              </nav>
            </el-col>
            <el-col :span="6">
              <h3 class="NotoSansTCMedium">{{ $t("localization.footer.nav")[3]["title"] }}</h3>
              <nav>
                <router-link class="link" to="/product_consulting">{{
                  $t("localization.footer.nav")[3]["link"][0]
                }}</router-link>
                <router-link class="link" to="/technical_support">{{
                  $t("localization.footer.nav")[3]["link"][1]
                }}</router-link>
                <div class="link">
                  {{ $t("localization.footer.nav")[3]["link"][2] }} {{ tel }}
                </div>
              </nav>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6" class="rightView">
          <div class="subscribe">
            <h3 class="NotoSansTCMedium">{{ $t("localization.footer.subscribe.title") }}</h3>
            <p>{{ $t("localization.footer.subscribe.txt") }}</p>
            <el-button
              class="subscribeBtn NotoSansTCMedium"
              type="primary"
              @click="subscribe"
              round
              >{{ $t("localization.footer.subscribe.btn") }}</el-button
            >
            <div class="txtView NotoSansTCLight">
              {{ $t("localization.footer.subscribe.content") }}
              <el-link @click="privacy_policy_link">{{
                $t("localization.footer.subscribe.privacy_policy")
              }}</el-link>
            </div>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="keep_on_record">
            <!-- <p>{{ $t("localization.footer.copyright") }}</p> -->
            <p>{{ copyright }}</p>
            <div class="linkWrap">
              <router-link class="link" to="/terms_of_use">{{
                $t("localization.footer.map")[0]
              }}</router-link>
              <el-divider direction="vertical"></el-divider>
              <router-link class="link" to="/privacy_policy">{{
                $t("localization.footer.map")[1]
              }}</router-link>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/**接口 */
export default {
  props: {
    //数据
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  //   watch: {
  //     // 显示
  //     show(new_val) {
  //       this.is_visible = new_val;
  //     },
  //   },
  //   filters: {},
  data() {
    return {
      tel: "",
      copyright: "",
    };
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    subscribe() {
      this.$emit("change");
    },
    privacy_policy_link() {
      this.$router.push({ path: "/privacy_policy" });
    },
    getConfig() {
      this.$http.getConfig().then((res) => {
        if (res.code == 200) {
          this.copyright = res.data.copyright;
          this.tel = res.data.tel;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
