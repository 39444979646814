<template>
  <div id="app" class="NotoSansTCRegular" @touchmove="touchmove">
    <head-view :active-name="activeName" />

    <router-view />
    <!-- <keep-alive>
      <router-view /> -->
    <!-- <router-view v-if="$route.meta.keepAlive" /> -->
    <!-- </keep-alive> -->
    <!-- <router-view v-if="!$route.meta.keepAlive" /> -->

    <foot-view @change="subscribe" v-if="screenWidth > 1024" />

    <el-dialog
      :visible.sync="dialogVisible"
      width="520px"
      :before-close="handleClose"
    >
      <subscribe-news @success="dialogVisible = false" dialog />
    </el-dialog>
  </div>
</template>
<script>
import headView from "@/components/head";
import footView from "@/components/foot";
import subscribeNews from "@/components/subscribeNews";
import store from "@/store";
import { mapState } from "vuex";
export default {
  components: {
    headView,
    footView,
    subscribeNews,
  },
  data() {
    return {
      activeName: "",
      dialogVisible: false,
      screenWidths: document.body.clientWidth,
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidths = window.screenWidth;
      })();
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
  },
  //监听路由变化
  watch: {
    /**
     * 监听路由
     */
    $route: {
      handler: function (to, form) {
        this.activeName = to.meta.name;
      },
      //立即先去执行handler方法
      immediate: true,
    },
    /**
     * 监听屏幕宽度
     */
    screenWidths(val) {
      store.commit("setScreenWidth", val);
    },
  },
  methods: {
    touchmove(e){
      e._isScrolleer = true
    },
    /**
     * 订阅按钮点击
     */
    subscribe() {
      this.dialogVisible = true;
    },
    /**
     * 关闭
     */
    handleClose(done) {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap");

.NotoSansTCLight {
  // font-family: "NotoSansTCLight";
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 300;
}

.NotoSansTCRegular {
  // font-family: "NotoSansTCRegular";
  font-family: "Noto Sans TC", sans-serif;
  // font-weight: 400;
  font-weight: 300;
}

.NotoSansTCMedium {
  // font-family: "NotoSansTCMedium";
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 500;
}

.NotoSansTCBold {
  // font-family: "NotoSansTCBold";
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 700;
}

// 引入全局样式
@import "@/assets/style/common.scss";
</style>
