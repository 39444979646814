/**
 * Mock 模拟接口
 * data里的属性看不懂，需要详细看语法规范,博客链接:https://www.jianshu.com/p/4579f40e6108
 */

let Mock = require('mockjs');

/**
 *  测试
 */
Mock.mock('/api/test', {
  "msg": "成功",
  "code": 1,
  "data": {
    "mtime": "@datetime", //随机生成日期时间
    "score|1-800": 800, //随机生成1-800的数字
    "rank|1-100": 100, //随机生成1-100的数字
    "stars|1-5": 5, //随机生成1-5的数字
    "nickname": "@cname", //随机生成中文名字
  }
});

// 首页
Mock.mock('/api/home', {
  "msg": "成功",
  "code": 1,
  "data": {
    "mtime": "@datetime", //随机生成日期时间
    "swiper|4": [{
      "id|+1": 1,
      "isVideo|1": true,
      "videoUrl": "mock/video/@natural(1, 3).mp4",
      "url": "@dataImage('1920x800')",
    }],
    "one|6": [{
      "id|+1": 1,
      "pic": "@dataImage('210x210')",
      "title": "@cname"
    }],
    "two|6": [{
      "id|+1": 1,
      "pic": "@dataImage('650x410')",
      "title": "行業應用-制藥行業",
      "introduce": "傳統的膠囊硬度測試是靠檢測凝膠材料的水分比例來判定, 用時太久不能及時反饋信息; Gelomat采用其特殊的力值測試方法大大提高了檢測效率只需10-20秒時間即可得到測試結果，且可直接在膠囊成品上完成精準的測量。"
    }],
    "three|4": [{
      "id|+1": 1,
      "pic": "@dataImage('465x240')",
      "title": "關於博銳",
      "introduce": "公司多年來致力於研發創新，以核心技術為中心"
    }],
    "five|3": [{
      "id|+1": 1,
      "pic": "@dataImage('375x245')",
      "title": "May 7th, 2020",
      "introduce": "溫度是否會影響硬度測值？HPE III是 壹臺首創具有溫感的邵氏硬度計"
    }]
  }
});


// 产品
Mock.mock('/api/product', {
  "msg": "成功",
  "code": 1,
  "data": {
    "list|23": [{
      "id|+1": 1,
      "pic": "@dataImage('210x210')",
      "title": "@cname"
    }],
  }
});

// 产品详情
Mock.mock('/api/productDetails', {
  "msg": "成功",
  "code": 1,
  "data": {
    "product_switching|3": [{
      "id|+1": 1,
      "name": "@cname",
      "children|4": [{
        "id|+1": 1,
        "name": "@cname",
        "application": "@cname",
        "standard": "@cname",
        "pic": "@dataImage('375x260')",
        "hardness": "@integer(0, 100)"
      }]
    }],
    "product_swiper|4": [{
      "id|+1": 1,
      "pic": "@dataImage('480x480')",
    }],
    "product_parts|6": ["@dataImage('240x180')"],
    "id": "01",
    "title": "DIGI TEST II",
    "linkList|3": [{
      "id|+1": 1,
      "pic": "@dataImage('375x245')",
      "span": "March 19th, 2020",
      "content": "如何有效的於高低溫的環境內做硬度 測試?"
    }],
    "specsList|3": [{
      "id|+1": 1,
      "name": "@cname"
    }],
    "partsList|8": [{
      "id|+1": 1,
      "pic": "@dataImage('360x360')",
      "name": "精准O型圈定位装置",
      "content": "傳統的膠囊硬度測試是靠檢測凝膠材料的水分比例來判定, 用時太久不能及時反饋信息; Gelomat采用其特殊的力值測試方法大大提高了檢測效率只需10-20秒時間即可得到測試結果，且可直接在膠囊成品上完成精準的測量。"
    }],
    "listData": [{
      "imgBgurl": "mock/ProductDetails/Hover-Normal.jpg",
      "title": "Micro",
      "list": [{
        "sort": "A",
        "name": "初力值",
        "value": "8.3 mN",
        "imgUrl": "mock/ProductDetails/Micro/Micro-A.png",
      },
      {
        "sort": "B",
        "name": "總力值",
        "value": "153.3 mN",
        "imgUrl": "mock/ProductDetails/Micro/Micro-B.png",
      },
      ],
    },
    {
      "imgBgurl": "mock/ProductDetails/Hover-Normal.jpg",
      "title": "Micro",
      "list": [{
        "sort": "A",
        "name": "初力值",
        "value": "8.3 mN",
        "imgUrl": "mock/ProductDetails/Micro/Micro-A.png",
      },
      {
        "sort": "B",
        "name": "總力值",
        "value": "153.3 mN",
        "imgUrl": "mock/ProductDetails/Micro/Micro-B.png",
      },
      ],
    },
    {
      "imgBgurl": "mock/ProductDetails/Hover-Normal.jpg",
      "title": "Micro",
      "list": [{
        "sort": "A",
        "name": "初力值",
        "value": "8.3 mN",
        "imgUrl": "mock/ProductDetails/Micro/Micro-A.png",
      },
      {
        "sort": "B",
        "name": "總力值",
        "value": "153.3 mN",
        "imgUrl": "mock/ProductDetails/Micro/Micro-B.png",
      },
      ],
    }
    ],
  }
});

// 配件
Mock.mock('/api/parts', {
  "msg": "成功",
  "code": 1,
  "data": {
    "parts|19": [{
      "id|+1": 1,
      "pic": "@dataImage('210x210')",
      "title": "@cname"
    }],
    "spare|19": [{
      "id|+1": 1,
      "pic": "@dataImage('210x210')",
      "title": "@cname"
    }]
  }
});

// 新闻列表
Mock.mock('/api/news', {
  "msg": "成功",
  "code": 1,
  "data": {
    "list": [{
      "id": 1,
      "pic": "@dataImage('790x525')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id": 2,
      "pic": "@dataImage('360x452')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id": 3,
      "pic": "@dataImage('790x525')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id|+1": 4,
      "pic": "@dataImage('375x245')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id|+1": 5,
      "pic": "@dataImage('375x245')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id|+1": 6,
      "pic": "@dataImage('375x245')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    ],
  }
});
// 新闻列表筛选数据
Mock.mock('/api/news_screen', {
  "msg": "成功",
  "code": 1,
  "data": {
    "category|2": [{
      "value|+1": 1,
      "label": "类别",
    }],
    "year|2": [{
      "value|+1": '@DATETIME("yyyy")',
    }],
  }
});

// 关于博锐
Mock.mock('/api/about', {
  "msg": "成功",
  "code": 1,
  "data": {
    "banner": "@dataImage('1920x800')",
    "history|10": [{
      "id|+1": 1,
      "pic": "@dataImage('370x300')",
      "txt": "當中國已成為全球的制造工廠時，博銳未來因應這市場的快速發展而產生的強大需求，決定在上海成立分公司，其長遠的發展希望將博銳這品牌更深入市場，並做同時去同地域的技術和業務的支持。",
      "year": "2018"
    }],
    "people|10": [{
      "id|+1": 1,
      "pic": "@dataImage('290x290')",
      "txt": "» 對於產品的質量和功能的穩定性，我們堅持達到最高標準。我們有足夠的信心在產品正常使用的狀態下以及定時的校準和保養，產品的壞損率幾乎為零。壹臺好的儀器不但能符合測量上的精準度和穩定度要求，加上使用壽命長，低維修成本所帶來的經濟效益遠大過於壹臺高質量與低質量的儀器本身價差。 «",
      "name": "Oliver Wirth",
      "position": "CEO - 研發中心總監"
    }],
    "video": {
      "videoUrl": "mock/video/@natural(1, 3).mp4",
      "pic": "@dataImage('900x500')",
    }
  }
});

// 校正与维修
Mock.mock('/api/calibrationAndMaintenance', {
  "msg": "成功",
  "code": 1,
  "data": {
    "one": {
      "pic": "@dataImage('490x270')",
      "tabList|5": [{
        "id|+1": 1,
        "title": "@ctitle",
        "content": "@cparagraph"
      }]
    },
    "two": {
      "downloadUrl": "http://www.cztouch.com/upfiles/soft/testpdf.pdf",
      "picList|3": ["@dataImage('470x930')"]
    },
    "three": {
      "pic": "@dataImage('294x422')",
      "data": [{
        "title": "Installation Qualification",
        "pic": "@dataImage('96x76')",
        "subtitle": "IQ(安裝驗證)",
        "content": "該驗證用於檢查被指定為“測試對象”設備的交付完整性和操作就緒情況。報告記錄了制造商指定的所有規格和參數，是否遵守安全規定，以及指導操作人員了解設備的基本功能和操作。",
        "list": [],
      },
      {
        "title": "Operation Qualification",
        "pic": "@dataImage('96x76')",
        "subtitle": "OQ(操作驗證)",
        "content": "該驗證用於檢查被指定為“測試對象”設備的操作和準備就緒情況。以下幾點在現場進行測試：",
        "list": ["所有與品質或過程相關的警報和切換功能。", "所有品質或過程相關的控制功能。", "測量環境條件（溫度和濕度）。", "測試所需的設定值（參數）的設置必須按照對應的操作說明進行操作。"]
      },
      {
        "title": "Performance Qualification",
        "pic": "@dataImage('96x76')",
        "subtitle": "PQ(性能驗證)",
        "content": "本文件協助您檢查與過程相關的條件下，功能和準備就緒情侶。現場記錄以下內容：",
        "list": ["所有品質或過程相關的控制和測量功能。", "硬度標準塊用作輔助材料。"]
      }
      ],
      "remarks": "註：博銳產品的3Q驗證程序都由原廠認證的校正工程師於客戶端現場執行，並在驗證過程中同時指導客戶產品的正確使用方式。詳情請洽博銳各分支機構。"
    },
    "four": {
      "pic": "@dataImage('290x290')",
      "content": "» 對於產品的質量和功能的穩定性，我們堅持達到最高標準。我們有足夠的信心在產品正常使用的狀態下以及定時的校準和保養，產品的壞損率幾乎為零。壹臺好的儀器不但能符合測量上的精準度和穩定度要求，加上使用壽命長，低維修成本所帶來的經濟效益遠大過於壹臺高質量與低質量的儀器本身價差。 «",
      "name": "Mickey Chen",
      "role": "服務工程師"
    },
    "project": {
      "pic": "./mock/1111.jpg",
      "list|4": [
        {
          "id|+1": 1,
          "title": "力值",
          "pic": "@dataImage('86x86')",
          "content": "使用博銳自主研發校準臺搭配高精度電子天平，以每10個邵氏單位（度）為壹個級距，將彈簧力值[MN]轉換成[g]，量測精度取至小數點後兩位。"
        }
      ]
    }
  }
});



// 下载中心 筛选1
Mock.mock('/api/downloadCenter_screen_one', {
  "msg": "成功",
  "code": 1,
  "data|10": [{
    "value|+1": 1,
    "label": "@cname",
  },]
});
// 下载中心 筛选2
Mock.mock('/api/downloadCenter_screen_two', {
  "msg": "成功",
  "code": 1,
  "data|10": [{
    "value|+1": 1,
    "label": "@cname",
  },]
});
// 下载中心
Mock.mock('/api/downloadCenter', {
  "msg": "成功",
  "code": 1,
  "data|10": [{
    "id|+1": 1,
    "pic": "@dataImage('23x29')",
    "type": "完整操作手册",
    "title": "@cword(7, 15)",
    "language": "中文",
    "size": "1.87M",
    "fileUrl": "http://www.cztouch.com/upfiles/soft/testpdf.pdf"
  }]
});

// 线上教学
Mock.mock('/api/onlineTeaching', {
  "msg": "成功",
  "code": 1,
  "data": {
    "teaching_category|5": [{
      "value|+1": 1,
      "label": "@cname",
    }],
    "product_model|5": [{
      "value|+1": 1,
      "label": "@cname",
    }]
  }
});
// 知识库列表数据
Mock.mock('/api/knowledgeBaseUpList', {
  "msg": "成功",
  "code": 1,
  "data|6": [{
    "id|+1": 1,
    "pic": "@dataImage('130x130')",
    "title": "@cname"
  }]
});
// 知识库
Mock.mock('/api/knowledgeBase', {
  "msg": "成功",
  "code": 1,
  "data|10": [{
    "id|+1": 1,
    "title": "邵氏硬度測量的方法不對會造成數值不準確嗎？ "
  }]
});
// 知识库详情
Mock.mock('/api/knowledgeBaseUpDetails', {
  "msg": "成功",
  "code": 1,
  "data": {
    "html": "富文本",
    "video": {
      "url": "mock/video/@natural(1, 3).mp4",
      "pic": "@dataImage('900x500')",
    }
  }
});
//知识库 教学视频
Mock.mock('/api/knowledgeBaseVideo', {
  "msg": "成功",
  "code": 1,
  "data|4": [{
    "id|+1": 1,
    "pic": "@dataImage('160x100')",
    "title": "溫度是否會影響硬度測值？HPE III是壹臺首創具有溫感的邵氏硬度計",
    "content": "在标准的规范里, 传统的硬度计受限在厚度需足6毫米, 面积需大于Ø18毫米的样品上做测试, 因此不适用于任何面积小, 厚度薄, 形状特殊的橡胶或塑料成品上做测试。",
    "videoUrl": "mock/video/@natural(1, 3).mp4",
  }],
});

//知识库 列表 tab数据
Mock.mock('/api/knowledgeBaseListTab', {
  "msg": "成功",
  "code": 1,
  "data|8": [{
    "id|+1": 1,
    "title": "@cname"
  }],
});
//知识库 列表
Mock.mock('/api/knowledgeBaseList', {
  "msg": "成功",
  "code": 1,
  "data|4": [{
    "id|+1": 1,
    "title": "溫度是否會影響硬度測值？HPE III是壹臺首創具有溫感的邵氏硬度計",
    "content": "DIGI TEST II 有别于一般传统硬度计, 原因在于被测品的条件受限上有着很大不同的尺度。在标准的规范里, 传统的硬度计受限在厚度需足6毫米, 面积需大于Ø18毫米的样品上做测试, 因此不适用于任何面积小, 厚度薄, 形状特殊的橡胶或塑料成品上做测试。DIGI TEST II的诞生就是为了克服这些限制。模组化的设计可在同一机体上转换Shore, IRHD 和VLRH全范围测法, 因次不管是标准件或成品件, 一台DIGI TEST II就能完全胜任。随着材料科技的日新月异以及产品成型方法的多元化, 精确的反应成品件的物理特性将越来越重要。",
  }],
});

//搜索列表
Mock.mock('/api/search', {
  "msg": "成功",
  "code": 1,
  "data|86": [{
    "id|+1": 1,
    "title": "溫度是否會影響硬度測值？HPE III是壹臺首創具有溫感的邵氏硬度計",
    "content": "DIGI TEST II 有别于一般传统硬度计, 原因在于被测品的条件受限上有着很大不同的尺度。在标准的规范里, 传统的硬度计受限在厚度需足6毫米, 面积需大于Ø18毫米的样品上做测试, 因此不适用于任何面积小, 厚度薄, 形状特殊的橡胶或塑料成品上做测试。DIGI TEST II的诞生就是为了克服这些限制。模组化的设计可在同一机体上转换Shore, IRHD 和VLRH全范围测法, 因次不管是标准件或成品件, 一台DIGI TEST II就能完全胜任。随着材料科技的日新月异以及产品成型方法的多元化, 精确的反应成品件的物理特性将越来越重要。",
    "state": "@natural(1, 3)"
  }],
});

//运营据点
Mock.mock('/api/operationBase', {
  "msg": "成功",
  "code": 1,
  "data": {
    "banner": {
      "pic": "@dataImage('1920x460')",
      "title": "營運據點",
      "content": "成立於1954年的博銳儀器，隨著全球制造業的發展軌跡，逐漸在歐洲以外成立據點，以便更貼近市場。"
    },
    "list": [{
      "id": 1,
      "pic": "@dataImage('790x525')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id": 2,
      "pic": "@dataImage('360x452')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id": 3,
      "pic": "@dataImage('790x525')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id|+1": 4,
      "pic": "@dataImage('375x245')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id|+1": 5,
      "pic": "@dataImage('375x245')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    {
      "id|+1": 6,
      "pic": "@dataImage('375x245')",
      "time": '@DATETIME("yyyy-MM-dd")',
      "subtitle": "@TITLE(1)",
      "title": "@cname",
      "content": "@cparagraph"
    },
    ],
  },
});

// 使用案例筛选
Mock.mock('/api/use_cases_screen', {
  "msg": "成功",
  "code": 1,
  "data": {
    "industry|2": [{
      "value|+1": 1,
      "label": "@cname",
    }],
    "product|2": [{
      "value|+1": '@cname',
    }],
    "region|2": [{
      "value|+1": '@region()',
    }],
  }
});

// 使用案例详情
Mock.mock('/api/use_cases_details', {
  "msg": "成功",
  "code": 1,
  "data": {
    "banner": "@dataImage('1920x800')",
    "block": {
      "logo": "@dataImage('250x146')",
      "title": "利乐包装",
      "link": "www.tetrapak.com",
      "content": "立於1951年的瑞典利樂公司早在1972年就進入中國市場。從中國改革開放到加入世界貿易組織，利樂壹直信奉“與中國客戶共同成長”的經營理念，致力於貫徹“確保安全的食品在任何地方皆舉手可得”的使命，不斷將先進的技術設備和完善的配套服務引進中國，積極推進生產服務的本地化進程，在中國液態食品包裝領域發揮著重要的作用。"
    },
    "html": "富文本",
    "pic": "@dataImage('1920x800')",
  }
});