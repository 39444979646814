<!--
 * @Author: 苏航
 * @Date: 2021-05-25 08:35:14
 * @LastEditors: 苏航
 * @LastEditTime: 2021-06-02 13:43:37
 * @version: V.x
 * @Descripttion: 说明...
-->
<template>
  <div class="emptyData" :style="`font-size:${fontSize}`">
    <img src="@/assets/images/empty-CN.png" alt="" srcset="" />
    <!-- <i :class="iconName" :style="`font-size:${iconSize}`"></i>
    {{ text }} -->
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default() {
        return this.$t("localization.components.empty_data");
      },
    },
    iconName: {
      type: String,
      default() {
        return "el-icon-folder-opened";
      },
    },
    iconSize: {
      type: String,
      default() {
        return "12px";
      },
    },
    fontSize: {
      type: String,
      default() {
        return "12px";
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.emptyData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
}
</style>