<template>
  <div class="header NotoSansTCLight" v-if="screenWidth > 1024">
    <router-link class="logo" to="/">
      <img src="@/assets/images/logo.png" alt="" />
    </router-link>
    <nav class="navView">
      <div v-for="(item, index) of nav_list" :key="index">
        <router-link
          class="link"
          :class="activeName == item.activeName ? 'activeShow' : ''"
          :to="item.link"
        >
          {{ item.label }}
        </router-link>
        <div class="children" v-if="item.hasOwnProperty('children')">
          <router-link
            class="link_f"
            v-for="(f_item, f_index) of item.children"
            :key="f_index"
            :class="activeName == f_item.activeName ? 'activeShow' : ''"
            :to="f_item.link"
          >
            {{ f_item.label }}
          </router-link>
        </div>
      </div>
    </nav>
    <div class="rightView">
      <el-popover placement="left" width="200" trigger="click">
        <el-input
          placeholder="搜索關鍵詞"
          @keyup.enter.native="searchSubmit"
          v-model="keyword"
          size="mini"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <i slot="reference" class="el-icon-search search"></i>
      </el-popover>

      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ lang_computed }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) of lang"
            :key="index"
            @click.native="changeDropdown(index)"
            >{{ item.label }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
  <div class="app-header" :class="{ show: nav_show }" v-else>
    <div class="app-header-box">
      <button
        type="button"
        class="toggle-nav"
        :class="nav_show ? 'on' : ''"
        @click="toggle_nav"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <router-link class="logo" to="/">
        <img src="@/assets/images/logo.png" alt="" />
      </router-link>
      <i
        class="el-input__icon el-icon-search app-search"
        @click="dialogVisible = true"
      ></i>
      <el-dropdown class="app-dropdown" trigger="click">
          <span>
            <img class="lang" src="@/assets/images/lang.png" alt="">
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) of lang"
              :key="index"
              @click.native="changeDropdown(index)"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
    </div>

    <!-- 移动端搜索弹出窗 start -->
    <el-dialog
      title="搜索"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose"
      append-to-body
    >
      <el-input
        placeholder="搜索關鍵詞"
        @keyup.enter.native="searchSubmit"
        v-model="keyword"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="searchSubmit">搜 索</el-button>
      </span>
    </el-dialog>
    <!-- 移动端搜索弹出窗 end -->

    <div class="app-nav" :class="nav_show ? 'show' : ''">
      <nav class="navView">
        <div v-for="(item, index) of nav_list" :key="index">
          <div
            class="link"
            :class="activeName == item.activeName ? 'activeShow' : ''"
            @click="router_link(item.link)"
          >
            {{ item.label }}
          </div>
          <div class="children" v-if="item.hasOwnProperty('children')">
            <div
              class="link link_f"
              v-for="(f_item, f_index) of item.children"
              :key="f_index"
              :class="activeName == f_item.activeName ? 'activeShow' : ''"
              @click="router_link(f_item.link)"
            >
              {{ f_item.label }}
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
/**接口 */
export default {
  props: {
    //高亮
    activeName: {
      type: String,
      default() {
        return "";
      },
    },
  },
  //监听路由变化
  watch: {
    activeName: {
      handler: function (to, form) {
        this.active = to;
      },
      // immediate: true,
    },
  },
  //   filters: {},
  data() {
    return {
      keyword: "",
      active: "",
      nav_list: [
        {
          link: "/product",
          activeName: "product",
          label: this.$t("localization.nav")[0],
          children: []
        },
        {
          link: "/parts",
          activeName: "parts",
          label: this.$t("localization.nav")[1],
        },
        {
          link: "/about",
          activeName: "about",
          label: this.$t("localization.nav")[2],
          children: [
            {
              link: "/news",
              activeName: "news",
              label: this.$t("localization.footer.nav")[0]["link"][1],
            },
            {
              link: "/use_cases",
              activeName: "use_cases",
              label: this.$t("localization.footer.nav")[0]["link"][2],
            },
            {
              link: "/operation_base",
              activeName: "operation_base",
              label: this.$t("localization.footer.nav")[0]["link"][3],
            },
          ],
        },
        {
          link: "/service_and_support",
          activeName: "service_and_support",
          label: this.$t("localization.nav")[3],
        },
      ],

      // langObj: {},
      lang: [
        {
          label: "繁中",
          value: "zh-TW",
          link: "http://www.bareiss.tw",
        },
        {
          label: "簡中",
          value: "zh-CN",
          link: "http://www.bareiss.cn",
        },
        {
          label: "English",
          value: "en",
          link: "http://www.bareiss.tw/en/#",
        },
        {
          label: "N.America",
          value: "en",
          link: "http://www.bareiss-testing.com",
        },
        {
          label: "Global",
          value: "en",
          link: "http://www.bareiss.de",
        },
      ],

      //导航
      nav_show: false,
      dialogVisible: false,
      productIndustry: []
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
    lang_computed: function () {
      let langData = this.lang.filter(
        (item) => item.value == this.$store.getters.getLang
      );
      return langData[0].label;
    },
  },
  mounted() {
    this.getProductIndustryListData();
  },
  methods: {
    /**
     * 搜索
     */
    searchSubmit() {
      if (this.keyword != "") {
        this.$router.push({
          path: "/search",
          query: { keyword: this.keyword },
        });
      }
      this.dialogVisible = false;
    },
    /**
     * 国际化
     */
    changeDropdown(e) {
      let link = this.lang[e].link;
      window.location.href = link;
      // if (locale == "zh-CN") {
      //   window.location.href = "http://bareiss.cn/";
      // }
      /*
      let locale = this.lang[e].value;
      // this.langObj = this.lang[e];
      this.$i18n.locale = locale;
      this.$store.dispatch("setLangName", locale);
      window.location.reload();
      */
      //在选择了显示的语言后，将配置保存到Cookie中
      // this.$cookie.set("DefaultLanguage", locale,1
      //     // {
      //     //   expires: "30m" //默认cookie有效时间为30分钟
      //     // }
      // );
    },
    /**
     * 导航
     */
    toggle_nav() {
      this.nav_show = !this.nav_show;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 跳转
     */
    router_link(path) {
      this.nav_show = false;
      this.$router.push({ path });
    },
    //获取行业列表
    getProductIndustryListData(){
      this.$http.getProductIndustryList().then(res => {
        let childrenArray = [];
        if (res.data.length > 0){
          res.data.map(item => {
            let childrenItem = {
              link: "/product",
              activeName: "product",
              label: ''
            };
            childrenItem.link = "/product" + '?industry_id=' + item.id;
            childrenItem.activeName = "/product" + '?industry_id=' + item.id;
            childrenItem.label = item.name;
            childrenArray.push(childrenItem);
          });
        }
        this.nav_list[0].children = childrenArray;
      })
    }
  },
};
</script>