/**
 * 跳转外部链接
 * @param {*} url  地址
 */
export const locationHref = (url) => {
	window.location.href = url
};

/**
 * 获取参数值
 * @param {*} name 参数
 */
export const getQueryString = (name) => {
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return unescape(r[2]);
	} else {
		return false;
	}
};

/**
 * 获取当前日期的后几天信息
 */
export const GetDateStr = (AddDayCount) => {
	var _date = new Date();
	var currentWeek = _date.getDay();
	_date.setDate(_date.getDate() + AddDayCount); //获取AddDayCount天后的日期  
	var years = _date.getFullYear();
	var months = (_date.getMonth() + 1) < 10 ? "0" + (_date.getMonth() + 1) : (_date.getMonth() + 1); //获取当前月份的日期，不足10补0  
	var days = _date.getDate() < 10 ? "0" + _date.getDate() : _date.getDate(); //获取当前几号，不足10补0  
	var dates = new Date(years, months - 1, days);
	var _week;
	if (dates.getDay() == 0) _week = "星期日";
	if (dates.getDay() == 1) _week = "星期一";
	if (dates.getDay() == 2) _week = "星期二";
	if (dates.getDay() == 3) _week = "星期三";
	if (dates.getDay() == 4) _week = "星期四";
	if (dates.getDay() == 5) _week = "星期五";
	if (dates.getDay() == 6) _week = "星期六";
	return {
		str: months + "月" + days + "日" + "[" + _week + "]",
		months,
		days,
		_week,
		years
	};
}


/**
 * 删除参数
 * @param {*} paramKey  参数
 */
export const delParam = (paramKey) => {
	var url = window.location.href; //页面url
	var urlParam = window.location.search.substr(1); //页面参数
	var beforeUrl = url.substr(0, url.indexOf("?")); //页面主地址（参数之前地址）
	var nextUrl = "";
	var arr = new Array();
	if (urlParam != "") {
		var urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
		for (var i = 0; i < urlParamArr.length; i++) {
			var paramArr = urlParamArr[i].split("="); //将参数键，值拆开
			//如果键雨要删除的不一致，则加入到参数中
			if (paramArr[0] != paramKey) {
				arr.push(urlParamArr[i]);
			}
		}
	}
	if (arr.length > 0) {
		nextUrl = "?" + arr.join("&");
	}
	url = beforeUrl + nextUrl;
	return url;
};

/**
 * 下载文件
 * @param {String} url 下载地址
 */
export const downloadFile = (url) => {
	var form = document.createElement("form");
	form.setAttribute("action", url);
	form.setAttribute("method", "get");
	form.setAttribute("target", "_blank");
	form.setAttribute("style", "display:none");
	document.body.appendChild(form);
	form.submit();
	document.body.removeChild(form);
};


/**
 * 滚动到顶部 
 */
export const scrollView = () => {
	// let top = document.documentElement.scrollTop;
	window.scrollTo(0, 0);
};