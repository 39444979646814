module.exports = {
  localization: {
    selectlanguage: "选择语言",
    type: "中文简体",
    // 共用
    nav: ["产品", "配件", "关于博锐", "服务与支持"],
    footer: {
      nav: [{
          title: "Bareiss",
          link: ["关于博锐", "新闻中心", "案例分享", "营运据点"]
        },
        {
          title: "产品",
          link: ["产品", "配件"]
        },
        {
          title: "服务与支持",
          link: ["校正与维修", "下载中心", "线上教学", "知识库"]
        },
        {
          title: "联系我们",
          link: ["产品咨询", "技术支持", "联系电话"]
        }
      ],
      subscribe: {
        title: "订阅我们的新闻",
        txt: "我们会将最新的博锐新闻直接发送到您的收件箱！",
        btn: "订阅我们的新闻",
        content: "如果您订阅我们的新闻通讯，则表示您同意我们的",
        privacy_policy: "隐私政策"
      },
      copyright: "Copyright © 2020 博锐亚太研发股份有限公司 版权所有",
      map: ["使用条款", "隐私政策"]
    },

    //组件
    components: {
      empty_data: "暂无数据",
      choice: {
        title: "为什么选择博锐",
        list: [{
            title: "质量保证",
            content: "高自制能力掌握每一个机械件的质量。百分之百德国生产，展示完美精致的机械工艺。"
          },
          {
            title: "智能测试",
            content: "壹机多测法能应用于各类弹性体的硬度测试。模块化的设计，即插即用。"
          },
          {
            title: "70+年的经验",
            content: "成立于1954年的博锐，至今已累计超过70年在弹性体物性测试的经验。"
          },
          {
            title: "DAkkS官方校正",
            content: "博锐自1996年起成为全德国第一家具备Shore/IRHD校正资格的官方校正实验室。"
          },
          {
            title: "试测服务",
            content: "我们诚挚的欢迎样品试测服务博锐于上海、台北、北卡莱纳州以及德国总部都设有实验设备。"
          },
          {
            title: "世界级客户的保证",
            content: "博锐的客户遍及全球并分布于各类产业。我们可以骄傲地说全世界500大企业，只要有涉及制造的几乎都是我们的客户。"
          }
        ]
      },
      screen: {
        title: "选择产品",
        test: {
          label: "测试",
          placeholder: "请选择测试"
        },
        standard: {
          label: "标准",
          placeholder: "请选择标准"
        },
        sample: {
          label: "样品",
          placeholder: "请选择样品"
        },
        product: {
          label: "产品",
          placeholder: "请选择产品"
        },
        refresh: "重置"
      },
      subscribeNews: {
        title: "订阅我们的新闻",
        txt1: "订阅我们的时事动态，并将最新的博锐新闻直接发送到您的收件箱！",
        txt2: "提交上述信息，即表示同意Bareiss的数据保护信息",
        name: "名字",
        language: "首选语言",
        mailbox: "您的邮箱",
        submit: "订 阅",
        verification: {
          name: "请输入姓名",
          language: "请选择首选语言",
          mailbox: "请输入邮箱",
          isEmail: "邮箱不合法"
        }
      },
      autoHardness: {
        title: "全自动弹性体硬度测试仪",
        content: "在标准的规范里，传统的硬度计受限在厚度需足6毫米，面积需大于Ø18毫米的样品上做测试，因此不适用于任何面积小，厚度薄，形状特殊的橡胶或塑料成品上做测试。",
        list: ["模块设计", "即插即用", "非平坦面测试", "超大尺寸", "超薄样厚", "测法转换", "USB2.0输出", "显示精度", "校正提醒", "完成品测试"]
      },
      hardness: {
        soft: "软",
        hard: "硬"
      },
      serviceAndSupport: {
        title: "服务与支持",
        nav: ["校正与维修", "下载中心", "在线教学", "知识库"]
      },
      contactUs: {
        title: "联系我们",
        introduce: "拥有最完善的测试设备，任何应用技术上的问题都可透过模拟的方式来了解问题的根本进而寻找出解决的方案。实验室也提供员工以及客户做技术课程训练使用。博锐逐渐迈向全球化，目前除了位于德国的总部提供样品代测和技术课程之外，位于上海的分公司也能提供同样服务给大中华区的客户。",
        link: [{
          title: "产品咨询",
          txt: "公司多年来致力于研发创新，以核心技术为中心",
          btn: "咨询"
        }, {
          title: "技术支持",
          txt: "公司为所有采购设备的客户提供上门安装培训服务",
          btn: "咨询"
        }]
      }
    },

    // 首页
    home: {
      more_details: "更多详情",
      all_products: "所有产品"
    },
    parts: {
      spare: "零件"
    },
    //rpa页面
    rpa:{
      rubber_analysis_tips: '请点击图标了解详细信息',
      pdf_download_title: '下载',
      pdf_download_tips: '有关更多信息，请使用我们的下载服务。',
      about_us_title: '始于1954年的品质保证',
      about_us: '关于我们',
      contact_us_title: '我们在这里为您服务',
      contact_us:'联系我们'
    }
  },
};