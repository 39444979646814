module.exports = {
  localization: {
    selectlanguage: "選擇語言",
    type: "中文繁體",
    // 共用
    nav: ["產品", "配件", "關於博銳", "服務與支持"],
    footer: {
      nav: [{
          title: "Bareiss",
          link: ["關於博銳", "新聞中心", "案例分享", "營運據點"]
        },
        {
          title: "產品",
          link: ["產品", "配件"]
        },
        {
          title: "服務與支持",
          link: ["校正與維修", "下載中心", "線上教學", "知識庫"]
        },
        {
          title: "聯繫我們",
          link: ["產品諮詢", "技術支持", "聯繫電話"]
        }
      ],
      subscribe: {
        title: "訂閱我們的新聞",
        txt: "我們會將最新的博銳新聞直接發送到您的收件箱！",
        btn: "訂閱我們的新聞",
        content: "如果您訂閱我們的新聞通訊，則表示您同意我們的",
        privacy_policy: "隱私政策"
      },
      copyright: "Copyright © 2020 博銳亞太研發股份有限公司 版權所有",
      map: ["使用條款", "隱私政策"]
    },

    //组件
    components: {
      empty_data: "暫無數據",
      choice: {
        title: "為什麼選擇博銳",
        list: [{
            title: "品質保證",
            content: "高自製能力掌握每一個機械件的質量。百分之百德國生產，展示完美精緻的機械工藝。"
          },
          {
            title: "智慧測試",
            content: "一機多測法能應用於各類彈性體的硬度測試。模組化的設計，隨插即用。"
          },
          {
            title: "70+年的經驗",
            content: "成立於1954年的博銳，至今已累計超過70年在彈性體物性測試的經驗。"
          },
          {
            title: "DAkkS官方校正",
            content: "博銳自1996年起成為全德國第一家具備Shore/IRHD校正資格的官方校正實驗室。"
          },
          {
            title: "試測服務",
            content: "我們誠摯的歡迎樣品試測服務博銳於上海、臺北、北卡萊納州以及德國總部都設有實驗設備。"
          },
          {
            title: "世界級客戶的保證",
            content: "博銳的客戶遍及全球並分佈於各類產業。我們可以驕傲地說全世界500大企業，只要有涉及製造的幾乎都是我們的客戶。"
          }
        ]
      },
      screen: {
        title: "選擇產品",
        test: {
          label: "測試",
          placeholder: "請選擇測試"
        },
        standard: {
          label: "標準",
          placeholder: "請選擇標準"
        },
        sample: {
          label: "樣品",
          placeholder: "請選擇樣品"
        },
        product: {
          label: "產品",
          placeholder: "請選擇產品"
        },
        refresh: "重置"
      },
      subscribeNews: {
        title: "訂閱我們的新聞",
        txt1: "訂閱我們的時事動態，並將最新的博銳新聞直接發送到您的收件箱！",
        txt2: "提交上述信息，即表示同意Bareiss的數據保護信息",
        name: "名字",
        language: "首選語言",
        mailbox: "您的郵箱",
        submit: "訂 閱",
        verification: {
          name: "請輸入姓名",
          language: "請選擇首選語言",
          mailbox: "請輸入郵箱",
          isEmail: "郵箱不合法"
        }
      },
      autoHardness: {
        title: "全自動彈性體硬度測試儀",
        content: "在標準的規範裏, 傳統的硬度計受限在厚度需足6毫米,面積需大於Ø18毫米的樣品上做測試, 因此不適用於任何面積小, 厚度薄,形狀特殊的橡膠或塑料成品上做測試。",
        list: ["模組設計", "即插即用", "非平坦面測試", "超大尺寸", "超薄樣厚", "測法轉換", "USB2.0輸出", "顯示精度", "校正提醒", "完成品測試"]
      },
      hardness: {
        soft: "軟",
        hard: "硬"
      },
      serviceAndSupport: {
        title: "服務與支持",
        nav: ["校正與維修", "下載中心", "線上教學", "知識庫"]
      },
      contactUs: {
        title: "聯系我們",
        introduce: "拥有最完善的测试设备，任何应用技术上的问题都可透过模拟的方式来了解问题的根本进而寻找出解决的方案。实验室也提供员工以及客户做技术课程训练使用。 博锐逐渐迈向全球化， 目前除了位于德国的总部提供样品代测和技术课程之外， 位于上海的分公司也能提供同样服务给大中华区的客户。 ",
        link: [{
          title: "產品咨詢",
          txt: "公司多年來致力於研發創新， 以核心技術為中心",
          btn: "咨詢"
        }, {
          title: "技術支持",
          txt: "公司為所有采購設備的客戶提供 上門安裝培訓服務",
          btn: "咨詢"
        }]
      }
    },

    // 首页
    home: {
      more_details: "更多詳情",
      all_products: "所有產品"
    },
    parts: {
      spare: "零件"
    },
    //rpa页面
    rpa:{
      rubber_analysis_tips: '請點擊圖標了解詳細資訊',
      pdf_download_title: '下載',
      pdf_download_tips: '有關更多信息，請使用我們的下載服務。',
      about_us_title: '始於1954年的品質保證',
      about_us: '關於我們',
      contact_us_title: '我們在這裡為您服務',
      contact_us:'聯繫我們'
    }
  },
};